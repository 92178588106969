const CounterTypes = {
  CREATE_MAP: "CREATE_MAP",
  CHANGE_DRAGGED_ELEMENT: "CHANGE_DRAGGED_ELEMENT",
  PLACE_TILE: "PLACE_TILE",
  SELECT_LAYER: "SELECT_LAYER",
  SET_BRUSH_SIZE: "SET_BRUSH_SIZE",
  ADD_LAYER: "ADD_LAYER",
  TOGGLE_GRID: "TOGGLE_GRID",
  LOAD_MAP: "LOAD_MAP",
  TOGGLE_ERASE_MODE: "TOGGLE_ERASE_MODE",
  TOGGLE_CLEAR_MODE: "TOGGLE_CLEAR_MODE",
  UPDATE_MAP_IMAGES: "UPDATE_MAP_IMAGES",
  FINISH_LOAD: "FINISH_LOAD",
  START_LOAD: "START_LOAD",
  SET_UNDO_STACK: "SET_UNDO_STACK",
  DELETE_LAYER: "DELETE_LAYER",
  CHANGE_LAYER_NAMES: "CHANGE_LAYER_NAMES",
};
export default {
  ...CounterTypes,
};
